import { createHashRouter, createRoutesFromElements, Route } from "react-router-dom";
import { ErrorBoundary } from "../components/error/ErrorBoundary";
import AppLayout from "../layout/AppLayout";
import { suspendPageLoad } from "./suspendPageLoad";

const Driving = suspendPageLoad(() => import("../pages/driving-analysis/DrivingPage"));

export const DRIVING_ROUTE = "/driving-analysis";
export const routes = [DRIVING_ROUTE];

export const router = createHashRouter(
	createRoutesFromElements(
		<Route path="/" element={<AppLayout />}>
			<Route path={DRIVING_ROUTE} errorElement={<ErrorBoundary />} element={<Driving />} />
		</Route>,
	),
);
