import { NavLink, useSearchParams } from "react-router-dom";
import { paramsFilterCards, paramsSearchBar, paramsSidesheetDashboard, paramsTruckName } from "../../utils/url-params";
import { useAppDispatch } from "../../configuration/setup/hooks";
import { setAppDate } from "../../stores/app/appSlice";
import { getLast24HoursPeriod, getTodayPeriod } from "../../utils/date";

export const LinkWithQuery = ({ children, to, ...props }: any) => {
	const [params] = useSearchParams();
	const dispatch = useAppDispatch();

	const newParams = new URLSearchParams(params.toString());
	newParams.delete(paramsSearchBar);
	newParams.delete(paramsFilterCards);
	newParams.delete(paramsSidesheetDashboard);
	newParams.delete(paramsTruckName);

	if (to === "/alerts" || to === "/downtime") {
		newParams.set("t", "true");
	} else if (to === "/dashboard") {
		newParams.set("t", "true");
		newParams.delete("date");
	} else {
		newParams.set("t", "false");
		newParams.delete("date");
	}

	return (
		<NavLink to={{ pathname: to, search: newParams.toString() }} {...props}>
			{children}
		</NavLink>
	);
};

export default LinkWithQuery;
