import ApplicationHeader from "@rio-cloud/rio-uikit/ApplicationHeader";
import SimpleTooltip from "@rio-cloud/rio-uikit/components/tooltip/SimpleTooltip";
import useOnlineStatus from "@rio-cloud/rio-uikit/useOnlineStatus";
import { DefaultUserMenu } from "@rio-cloud/rio-user-menu-component";
import IframeResizer from "iframe-resizer-react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { LinkWithQuery } from "../../components/link/LinkWithQuery";
import { config } from "../../config";
import { DRIVING_ROUTE } from "../../routes/Router";
import ServiceInfo from "./ServiceInfo";
import { getThemeFromDocument, STYLE_RIO, STYLE_RIO_LIGHT, Themes } from "../../components/common/header/Themes";
import packageJson from "../../../package.json";
const CDN_URL = "https://uikit.developers.rio.cloud";

const AppHeader = () => {
	const [showHelpDialog, setShowHelpDialog] = useState(false);
	const environment = import.meta.env.PROD ? "production" : "local";

	const navItems = [
		{
			key: "driving",
			route: (
				<LinkWithQuery to={DRIVING_ROUTE}>
					<FormattedMessage id={"general.app.page.driving"} />
				</LinkWithQuery>
			),
		},
	];

	const isOnline = useOnlineStatus();
	const serviceInfoItem = <ServiceInfo />;
	const userMenuItem = <DefaultUserMenu environment={environment} />;
	const helpDialog = (
		<SimpleTooltip content={"Ajuda"} placement="bottom">
			<span className={"rioglyph rioglyph-question-sign text-size-h3"} onClick={() => setShowHelpDialog(true)} />
		</SimpleTooltip>
	);

	const toggleTheme = (style = "", href?: string) => {
		const html = document.documentElement;

		// Remove any theme css before applying a new theme
		const headerLinks = [...document.head.querySelectorAll("link")];
		headerLinks.forEach((headerLink) => {
			if (
				headerLink.href.includes("vw-") ||
				headerLink.href.includes("man-") ||
				headerLink.href.includes("scania-") ||
				headerLink.href.includes("traton-")
			) {
				document.head.removeChild(headerLink);
			}
		});

		// Remove the theme class name
		html.classList.remove("style-vw");
		html.classList.remove("style-man");
		html.classList.remove("style-scania");
		html.classList.remove("style-traton");

		if (style === "RIO") {
			return;
		}

		// Add the theme CSS import
		const styleName = style.toLowerCase();

		const isDev = import.meta.env.DEV;

		const source = CDN_URL;
		const linkRef = href || `${source}/${packageJson.version}/${styleName}-uikit.css`;

		const link = document.createElement("link");
		link.href = linkRef;
		link.type = "text/css";
		link.rel = "stylesheet";
		document.head.appendChild(link);

		html.classList.add(`style-${style.toLowerCase()}`);
	};

	const getInitialTheme = () => {
		const documentTheme = getThemeFromDocument();
		return documentTheme ? `RIO_${documentTheme.toUpperCase()}` : STYLE_RIO_LIGHT;
	};

	const [theme, setTheme] = useState(getInitialTheme());

	const handleThemeChange = (themeWithMode: string) => {
		const newTheme = themeWithMode.startsWith(STYLE_RIO) ? themeWithMode.split("-")[0] : themeWithMode;
		toggleTheme(newTheme);

		// Store brand style + light/dark theme in context to be used to switch
		// intro image composition
		setTheme(themeWithMode);
	};

	const swatch = <Themes onThemeChange={handleThemeChange} />;

	const isAppOnline = isOnline ? (
		<SimpleTooltip content={"Sistema online"} placement="bottom">
			<span className="text-success rioglyph rioglyph-ok-sign text-size-h3" />
		</SimpleTooltip>
	) : (
		<SimpleTooltip content={"Sistema offline"} placement="bottom">
			<span className="text-danger rioglyph rioglyph-remove-sign text-size-h3" />
		</SimpleTooltip>
	);

	return (
		<>
			<ApplicationHeader
				label={<FormattedMessage id="general.app.name" />}
				appNavigator={<IframeResizer className="iFrameResizer" src={config.backend.MENU_SERVICE} />}
				homeRoute={<Link to={config.homeRoute || ""} />}
				navItems={navItems}
				actionBarItems={[isAppOnline, swatch, helpDialog, serviceInfoItem, userMenuItem]}
			/>
		</>
	);
};

export default AppHeader;
