import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import configReducer from "./configSlice";
import loginReducer from "../login/loginSlice";
import langReducer from "../lang/langSlice";
import tokenReducer from "../tokenHandling/tokenSlice";
import appReducer from "../../stores/app/appSlice";
import { truckApi } from "../../services/truckApi";
import { drivingApi } from "../../services/drivingApi";

export const store = configureStore({
	reducer: {
		config: configReducer,
		lang: langReducer,
		app: appReducer,
		login: loginReducer,
		tokenHandling: tokenReducer,
		[drivingApi.reducerPath]: drivingApi.reducer,
		[truckApi.reducerPath]: truckApi.reducer,
	},

	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(truckApi.middleware, drivingApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
