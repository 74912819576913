import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeaders } from "../configuration/tokenHandling/prepareHeaders";

export const truckApi = createApi({
	reducerPath: "truckApi",
	baseQuery: fetchBaseQuery({ prepareHeaders }),
	endpoints: (builder) => ({
		getAssets: builder.query<any, void>({
			query: () => ({
				url: "https://api.assets.rio.cloud/assets?embed=(tags)",
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data.items;
				},
			}),
		}),
		getTags: builder.query<any, void>({
			query: () => ({
				url: "https://api.tags.rio.cloud/tags",
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data.items;
				},
			}),
		}),
	}),
});

export const { useGetAssetsQuery, useGetTagsQuery } = truckApi;
