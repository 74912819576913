import { matchRoutes, useLocation } from "react-router-dom";

const useCurrentPath = () => {
	const location = useLocation();

	const getCurrentPath = () => {
		const routes = [{ path: "driving-analysis" }];
		const match = matchRoutes(routes, location);

		if (!match) {
			return null;
		}

		const [{ route }] = match;

		return route.path;
	};

	return getCurrentPath();
};

export default useCurrentPath;
