import React, { useState } from "react";
import PropTypes from "prop-types";
import className from "classnames";

import { setColorScheme } from "@rio-cloud/rio-uikit/utils/colorScheme";
import ActionBarItem from "@rio-cloud/rio-uikit/ActionBarItem";

const colorsRIO = {
	primary: "#30b4c0",
	secondary: "#b23672",
	info: "#4B80A6",
	success: "#5cb85c",
	warning: "#ff8e3c",
	danger: "#d90000",
};

const colorsDark = {
	primary: "#30b4c0",
	secondary: "#b23672",
	info: "#4B80A6",
	success: "#5cb85c",
	warning: "#ff8e3c",
	danger: "#d90000",
};

const colorsMAN = {
	primary: "#303c49",
	secondary: "#d22b40",
	info: "#4b96d2",
	success: "#71b564",
	warning: "#ff8e3c",
	danger: "#d90000",
};

const colorsVW = {
	primary: "#004666",
	secondary: "#b23672",
	info: "#4B80A6",
	success: "#64a844",
	warning: "#ff9b00",
	danger: "#da0c1f",
};

const colorsSCANIA = {
	primary: "#081c38",
	secondary: "#132d55",
	info: "#4580e9",
	success: "#3d9e81",
	warning: "#ebba3d",
	danger: "#cd3835",
};

const colorsTRATON = {
	primary: "#328092",
	secondary: "#d22b40",
	info: "#4b96d2",
	success: "#71b564",
	warning: "#ff8e3c",
	danger: "#d90000",
};

const THEME_DATA_ATTRIBUTE = "data-theme";

export const STYLE_RIO = "RIO";
export const STYLE_RIO_LIGHT = "RIO_LIGHT";
export const STYLE_RIO_DARK = "RIO_DARK";
export const STYLE_MAN = "MAN";
export const STYLE_SCANIA = "SCANIA";
export const STYLE_TRATON = "TRATON";
export const STYLE_VW = "VW";

export const getThemeFromDocument = () => document.documentElement.getAttribute(THEME_DATA_ATTRIBUTE);

export const Themes = ({ onThemeChange }: any) => {
	const [isHover, setIsHover] = useState(false);

	const handleMouseEnter = () => setIsHover(true);
	const handleMouseLeave = () => setIsHover(false);

	const handleThemeSwitch = (style: any) => {
		const root = document.documentElement;

		if (style.startsWith(STYLE_RIO)) {
			onThemeChange(style);
			if (style === STYLE_RIO_DARK) {
				root.setAttribute(THEME_DATA_ATTRIBUTE, "dark");
				setColorScheme("dark");
			} else {
				root.setAttribute(THEME_DATA_ATTRIBUTE, "light");
				setColorScheme("light");
			}
		} else {
			root.removeAttribute(THEME_DATA_ATTRIBUTE);
			onThemeChange(style);
			setColorScheme("default");
		}
	};

	const listItemClassnames = className(
		"display-flex padding-x-15 padding-y-10",
		"hover-bg-lightest cursor-pointer",
		"border border-bottom-only border-color-lighter last-child-border-bottom-none",
	);

	const lastListItemClassnames = className("display-flex padding-x-15 padding-y-10 cursor-pointer rounded-bottom");

	const darkModeListItemStyles = {
		backgroundColor: isHover ? "#0f1111" : "#151515",
		color: "#8f999c",
	};

	return (
		<ActionBarItem id="themes" mobileDialogBodyClassName="padding-0">
			<ActionBarItem.Icon>
				<span className="icon rioglyph rioglyph-color-swatch" />
			</ActionBarItem.Icon>
			<ActionBarItem.Popover title="Choose a Theme" className="padding-0">
				<div>
					<div className={listItemClassnames} onClick={() => handleThemeSwitch(STYLE_MAN)}>
						<div className="flex-1-1">MAN</div>
						<ColorSwatch colorSet={colorsMAN} />
					</div>
					<div className={listItemClassnames} onClick={() => handleThemeSwitch(STYLE_SCANIA)}>
						<div className="flex-1-1">SCANIA</div>
						<ColorSwatch colorSet={colorsSCANIA} />
					</div>
					<div className={listItemClassnames} onClick={() => handleThemeSwitch(STYLE_TRATON)}>
						<div className="flex-1-1">TRATON</div>
						<ColorSwatch colorSet={colorsTRATON} />
					</div>
					<div className={listItemClassnames} onClick={() => handleThemeSwitch(STYLE_VW)}>
						<div className="flex-1-1">Volkswagen</div>
						<ColorSwatch colorSet={colorsVW} />
					</div>
					<div className={listItemClassnames} onClick={() => handleThemeSwitch(STYLE_RIO_LIGHT)}>
						<div className="flex-1-1">RIO Light</div>
						<ColorSwatch colorSet={colorsRIO} />
					</div>
					<div
						className={lastListItemClassnames}
						style={darkModeListItemStyles}
						onClick={() => handleThemeSwitch(STYLE_RIO_DARK)}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						<div className="flex-1-1">RIO Dark</div>
						<div className="display-flex column-gap-5 justify-content-end">
							<ColorSwatch colorSet={colorsDark} />
						</div>
					</div>
				</div>
			</ActionBarItem.Popover>
		</ActionBarItem>
	);
};

const ColorSwatch = ({ colorSet }: any) => (
	<div className="display-flex column-gap-5 justify-content-end">
		<span className="rounded width-15 height-15" style={{ backgroundColor: colorSet.primary }} />
		<span className="rounded width-15 height-15" style={{ backgroundColor: colorSet.secondary }} />
		<span className="rounded width-15 height-15" style={{ backgroundColor: colorSet.info }} />
		<span className="rounded width-15 height-15" style={{ backgroundColor: colorSet.success }} />
		<span className="rounded width-15 height-15" style={{ backgroundColor: colorSet.warning }} />
		<span className="rounded width-15 height-15" style={{ backgroundColor: colorSet.danger }} />
	</div>
);
